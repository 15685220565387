import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-251df0e3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "personCard" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_cascader = _resolveComponent("el-cascader")!
  const _component_el_radio = _resolveComponent("el-radio")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_form, {
      ref: "formRef",
      model: _ctx.form,
      rules: _ctx.rules,
      "label-width": "100px"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, { span: 11 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_form_item, {
                  label: _ctx.$t('person.form.code'),
                  prop: "code"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      onInput: _cache[0] || (_cache[0] = e => _ctx.form.code = _ctx.valid(e)),
                      modelValue: _ctx.form.code,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.code) = $event)),
                      maxlength: "10",
                      placeholder: _ctx.$t('person.form.code_placeholder'),
                      disabled: _ctx.otherParams.disableField || _ctx.disabled,
                      clearable: ""
                    }, null, 8, ["modelValue", "placeholder", "disabled"])
                  ]),
                  _: 1
                }, 8, ["label"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, {
              span: 11,
              offset: 2
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_form_item, {
                  label: _ctx.$t('person.form.name'),
                  prop: "name"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      onInput: _cache[2] || (_cache[2] = e => _ctx.form.name = _ctx.valid(e)),
                      modelValue: _ctx.form.name,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.name) = $event)),
                      maxlength: "50",
                      placeholder: _ctx.$t('person.form.name_placeholder'),
                      disabled: _ctx.disabled,
                      clearable: ""
                    }, null, 8, ["modelValue", "placeholder", "disabled"])
                  ]),
                  _: 1
                }, 8, ["label"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        (_ctx.utils.Tools.getPropFromVuex('needCorp') || _ctx.utils.Tools.getPropFromVuex('needDept'))
          ? (_openBlock(), _createBlock(_component_el_row, { key: 0 }, {
              default: _withCtx(() => [
                (_ctx.utils.Tools.getPropFromVuex('needCorp'))
                  ? (_openBlock(), _createBlock(_component_el_col, {
                      key: 0,
                      span: 11
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_form_item, {
                          label: _ctx.$t('person.form.corpId'),
                          prop: "corpId"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_cascader, {
                              modelValue: _ctx.form.corpId,
                              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.corpId) = $event)),
                              style: {"width":"100%"},
                              options: _ctx.corpTreeData,
                              disabled: _ctx.otherParams.disableField || _ctx.disabled,
                              onChange: _cache[5] || (_cache[5] = ((selItem) => {_ctx.utilInst.corpChange(selItem, _ctx.utilInst)}))
                            }, null, 8, ["modelValue", "options", "disabled"])
                          ]),
                          _: 1
                        }, 8, ["label"])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.utils.Tools.getPropFromVuex('needDept'))
                  ? (_openBlock(), _createBlock(_component_el_col, {
                      key: 1,
                      span: 11,
                      offset: _ctx.utils.Tools.getPropFromVuex('needCorp')?2:0
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_form_item, {
                          label: _ctx.$t('person.form.deptId'),
                          prop: "deptId"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_cascader, {
                              modelValue: _ctx.form.deptId,
                              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.form.deptId) = $event)),
                              style: {"width":"100%"},
                              disabled: _ctx.otherParams.disableField || _ctx.disabled,
                              options: _ctx.deptTreeData
                            }, null, 8, ["modelValue", "disabled", "options"])
                          ]),
                          _: 1
                        }, 8, ["label"])
                      ]),
                      _: 1
                    }, 8, ["offset"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_el_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, { span: 11 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_form_item, {
                  label: _ctx.$t('person.form.title'),
                  prop: "title"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      onInput: _cache[7] || (_cache[7] = e => _ctx.form.title = _ctx.valid(e)),
                      modelValue: _ctx.form.title,
                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.form.title) = $event)),
                      maxlength: "100",
                      placeholder: _ctx.$t('person.form.title_placeholder'),
                      disabled: _ctx.otherParams.disableField || _ctx.disabled,
                      clearable: ""
                    }, null, 8, ["modelValue", "placeholder", "disabled"])
                  ]),
                  _: 1
                }, 8, ["label"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, {
              span: 11,
              offset: 2
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_form_item, {
                  label: _ctx.$t('person.form.position'),
                  prop: "position"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      onInput: _cache[9] || (_cache[9] = e => _ctx.form.position = _ctx.valid(e)),
                      modelValue: _ctx.form.position,
                      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.form.position) = $event)),
                      maxlength: "100",
                      placeholder: _ctx.$t('person.form.position_placeholder'),
                      disabled: _ctx.otherParams.disableField || _ctx.disabled,
                      clearable: ""
                    }, null, 8, ["modelValue", "placeholder", "disabled"])
                  ]),
                  _: 1
                }, 8, ["label"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, { span: 11 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_form_item, {
                  label: _ctx.$t('person.form.officeTel'),
                  prop: "officeTel"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      onInput: _cache[11] || (_cache[11] = e => _ctx.form.officeTel = _ctx.valid(e)),
                      modelValue: _ctx.form.officeTel,
                      "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.form.officeTel) = $event)),
                      maxlength: "11",
                      placeholder: _ctx.$t('person.form.officeTel_placeholder'),
                      disabled: _ctx.disabled,
                      clearable: ""
                    }, null, 8, ["modelValue", "placeholder", "disabled"])
                  ]),
                  _: 1
                }, 8, ["label"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, {
              span: 11,
              offset: 2
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_form_item, {
                  label: _ctx.$t('person.form.homeTel'),
                  prop: "homeTel"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      onInput: _cache[13] || (_cache[13] = e => _ctx.form.homeTel = _ctx.valid(e)),
                      modelValue: _ctx.form.homeTel,
                      "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.form.homeTel) = $event)),
                      maxlength: "11",
                      placeholder: _ctx.$t('person.form.homeTel_placeholder'),
                      disabled: _ctx.disabled,
                      clearable: ""
                    }, null, 8, ["modelValue", "placeholder", "disabled"])
                  ]),
                  _: 1
                }, 8, ["label"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, { span: 11 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_form_item, {
                  label: _ctx.$t('person.form.mobile'),
                  prop: "mobile"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      onInput: _cache[15] || (_cache[15] = e => _ctx.form.mobile = _ctx.valid(e)),
                      modelValue: _ctx.form.mobile,
                      "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.form.mobile) = $event)),
                      modelModifiers: { number: true },
                      maxlength: "11",
                      placeholder: _ctx.$t('person.form.mobile_placeholder'),
                      disabled: _ctx.disabled,
                      clearable: ""
                    }, null, 8, ["modelValue", "placeholder", "disabled"])
                  ]),
                  _: 1
                }, 8, ["label"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, {
              span: 11,
              offset: 2
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_form_item, {
                  label: _ctx.$t('person.form.email'),
                  prop: "email"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      onInput: _cache[17] || (_cache[17] = e => _ctx.form.email = _ctx.valid(e)),
                      modelValue: _ctx.form.email,
                      "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.form.email) = $event)),
                      maxlength: "40",
                      placeholder: _ctx.$t('person.form.email_placeholder'),
                      disabled: _ctx.disabled,
                      clearable: ""
                    }, null, 8, ["modelValue", "placeholder", "disabled"])
                  ]),
                  _: 1
                }, 8, ["label"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, { span: 11 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_form_item, {
                  label: _ctx.$t('person.form.sex'),
                  prop: "sex"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_radio_group, {
                      modelValue: _ctx.form.sex,
                      "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.form.sex) = $event)),
                      disabled: _ctx.disabled
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_radio, { label: 1 }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('man')), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_el_radio, { label: 0 }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('woman')), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["modelValue", "disabled"])
                  ]),
                  _: 1
                }, 8, ["label"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, {
              span: 11,
              offset: 2
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_form_item, {
                  label: _ctx.$t('person.form.birthdate'),
                  prop: "birthdate"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_date_picker, {
                      modelValue: _ctx.form.birthdate,
                      "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.form.birthdate) = $event)),
                      placeholder: _ctx.$t('person.form.birthdate_placeholder'),
                      disabled: _ctx.disabled
                    }, null, 8, ["modelValue", "placeholder", "disabled"])
                  ]),
                  _: 1
                }, 8, ["label"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, { span: 11 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_form_item, {
                  label: _ctx.$t('person.form.sfz'),
                  prop: "sfz"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      onInput: _cache[21] || (_cache[21] = e => _ctx.form.sfz = _ctx.valid(e)),
                      modelValue: _ctx.form.sfz,
                      "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.form.sfz) = $event)),
                      maxlength: "30",
                      placeholder: _ctx.$t('person.form.sfz_placeholder'),
                      disabled: _ctx.disabled,
                      clearable: ""
                    }, null, 8, ["modelValue", "placeholder", "disabled"])
                  ]),
                  _: 1
                }, 8, ["label"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, { span: 24 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_form_item, {
                  label: _ctx.$t('person.form.desc'),
                  prop: "desc"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      type: "textarea",
                      onInput: _cache[23] || (_cache[23] = e => _ctx.form.desc = _ctx.valid(e)),
                      modelValue: _ctx.form.desc,
                      "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.form.desc) = $event)),
                      maxlength: "125",
                      placeholder: _ctx.$t('person.form.desc_placeholder'),
                      disabled: _ctx.disabled,
                      clearable: ""
                    }, null, 8, ["modelValue", "placeholder", "disabled"])
                  ]),
                  _: 1
                }, 8, ["label"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model", "rules"])
  ]))
}